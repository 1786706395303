import React from "react";

import { LimitMaxWidth, SectionHeading } from "../layout";

// 1. Copy HTML exported from Termly
// 2. Simplify with
//       pbpaste | pandoc -f html -t markdown_strict-raw_html-native_divs-native_spans-fenced_divs-bracketed_spans | pandoc -t html | pbcopy
// 3. Paste as a JSX block in document
// 4. Autoformat in VSCode (<SHIFT> + <OPTION> + F)
// 4. Extract `updated`
// 5. Extract `intro`
// 6. Create `sections` list, with `id: string`, `heading: string` and `body: JSX` attributes
// 7. Convert all nonstandard code punctuation characters to their ascii equivalents
// 8. Add <p className="mt-4"> to all `p`s, then remove where not required
// 9. Make all ul and li look like <ul className="list-inside list-disc" className="list-inside list-disc"> <li className="list-item">
// 10. Make all anchors be coloured and bold <a className="text-syr-snd font-nunb"

type PolicySectionType = {
  policy: string;
  updated: string;
  intro: React.ReactNode,
  sections: {
    id: string;
    heading: string;
    body: React.ReactNode;
  }[];
}

const PolicySection: React.FC<PolicySectionType> = ({ policy, updated, intro, sections }) => <>
  <div className="py-12">
    <LimitMaxWidth>
      <div className="px-16 flex justify-center">
        <div className="max-w-3xl">
          <SectionHeading>
            {policy}
          </SectionHeading>
          <div className="mt-12">
            <div className="text-18 font-nunb text-syr-snd">
              {`Last updated: ${updated}`}
            </div>
            <div className="mt-8 text-b2 font-nun text-black">
              {intro}
            </div>
          </div>
          <div className="mt-12">
            {sections.map((sec, secIdx) => <>
              <div className="mt-4 p-8 bg-syr-smoke">
                <div className="-mt-48 pt-48" id={sec.id} > {/* Gaming the offsets to allow anchor links to work */}
                  <h4 className="text-subb font-nunb text-black">
                    {`${secIdx+1}. ${sec.heading}`}
                  </h4>
                  <div className="text-b2 font-nun text-black">
                    {sec.body}
                  </div>
                </div>
              </div>
            </>)}
          </div>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>

export default PolicySection;
