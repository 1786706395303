import React from "react";

import { Base } from "../components/layout"
import PolicySection from "../components/page-sections/PolicySection";
import SEO from "../components/seo";

export const Head = () => <SEO title="Syrona Health | Privacy Policy" />

const PrivacyPage: React.FC = () => <Base>
  <PolicySection policy="Privacy Policy" updated={updated} intro={intro} sections={sections} />
</Base>

export default PrivacyPage;

const updated = "28 March 2023"

const intro = <>
    <p className="mt-4">
      This privacy notice for Syrona Limited (doing business as Syrona Health)
      ('<strong>Syrona Health</strong>', '<strong>we</strong>', '
      <strong>us</strong>', or '<strong>our</strong>'), describes how and why we
      might collect, store, use, and/or share ('<strong>process</strong>') your
      information when you use our services ('<strong>Services</strong>'), such
      as when you:
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          Visit our website at{" "}
          <a className="text-syr-snd font-nunb" href="https://syronahealth.com">https://syronahealth.com</a>, or
          any website of ours that links to this privacy notice
      </li>
      <li className="list-item">
          Engage with us in other related ways, including any sales, marketing,
          or events
      </li>
    </ul>
    <p className="mt-4">
      <strong>Questions or concerns? </strong>Reading this privacy notice will
      help you understand your privacy rights and choices. If you do not agree
      with our policies and practices, please do not use our Services. If you
      still have any questions or concerns, please contact us at
      info@syronawomen.com.
    </p>
    <p className="mt-4">
      <strong>SUMMARY OF KEY POINTS</strong>
    </p>
    <p className="mt-4">
      <strong>What personal information do we process?</strong> When you visit,
      use, or navigate our Services, we may process personal information
      depending on how you interact with Syrona Health and the Services, the
      choices you make, and the products and features you use. Learn more about {" "}
      <a className="text-syr-snd font-nunb" href="#personalinfo">personal information you disclose to us</a>.
    </p>
    <p className="mt-4">
      <strong>Do we process any sensitive personal information?</strong> We do
      not process sensitive personal information.
    </p>
    <p className="mt-4">
      <strong>Do we receive any information from third parties?</strong> We do
      not receive any information from third parties.
    </p>
    <p className="mt-4">
      <strong>How do we process your information?</strong> We process your
      information to provide, improve, and administer our Services, communicate
      with you, for security and fraud prevention, and to comply with law. We
      may also process your information for other purposes with your consent. We
      process your information only when we have a valid legal reason to do so.
      Learn more about <a className="text-syr-snd font-nunb" href="#infouse">how we process your information</a>.
    </p>
    <p className="mt-4">
      <strong>
        In what situations and with which parties do we share personal
        information?
      </strong>{" "}
      We may share information in specific situations and with specific third
      parties. Learn more about{" "} 
      <a className="text-syr-snd font-nunb" href="#whoshare">
        when and with whom we share your personal information
      </a>
      .
    </p>
    <p className="mt-4">
      <strong>How do we keep your information safe?</strong> We have
      organisational and technical processes and procedures in place to protect
      your personal information. However, no electronic transmission over the
      internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorised third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information. Learn
      more about <a className="text-syr-snd font-nunb" href="#infosafe">how we keep your information safe</a>.
    </p>
    <p className="mt-4">
      <strong>What are your rights?</strong> Depending on where you are located
      geographically, the applicable privacy law may mean you have certain
      rights regarding your personal information. Learn more about{" "} 
      <a className="text-syr-snd font-nunb" href="#privacyrights">your privacy rights</a>.
    </p>
    <p className="mt-4">
      <strong>How do you exercise your rights?</strong> The easiest way to
      exercise your rights is by submitting a{" "} 
      <a className="text-syr-snd font-nunb" href="https://app.termly.io/notify/4ee475d0-95d4-4a88-b674-c6f3ded66abc">
        data subject access request
      </a>
      , or by contacting us. We will consider and act upon any request in
      accordance with applicable data protection laws.
    </p>
</>

const sections = [
  {
    id: "infocollect",
    heading: "What information do we collect?",
    body: <>
    <p className="mt-4">
      <strong>Personal information you disclose to us</strong>
    </p>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
        <em> </em>
      </strong>
      <em>We collect personal information that you provide to us.</em>
    </p>
    <p className="mt-4">
      We collect personal information that you voluntarily provide to us when
      you express an interest in obtaining information about us or our products
      and Services, when you participate in activities on the Services, or
      otherwise when you contact us.
    </p>
    <p className="mt-4">
      <strong>Personal Information Provided by You.</strong> The personal
      information that we collect depends on the context of your interactions
      with us and the Services, the choices you make, and the products and
      features you use. The personal information we collect may include the
      following:
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
        names
      </li>
      <li className="list-item">
        phone numbers
      </li>
      <li className="list-item">
        email addresses
      </li>
      <li className="list-item">
        job titles
      </li>
      <li className="list-item">
        company name
      </li>
    </ul>
    <p className="mt-4">
      <strong>Sensitive Information.</strong> We do not process sensitive
      information.
    </p>
    <p className="mt-4">
      All personal information that you provide to us must be true, complete,
      and accurate, and you must notify us of any changes to such personal
      information.
    </p>
    <p className="mt-4">
      <strong>Information automatically collected</strong>
    </p>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
        <em> </em>
      </strong>
      <em>
        Some information — such as your Internet Protocol (IP) address and/or
        browser and device characteristics — is collected automatically when you
        visit our Services.
      </em>
    </p>
    <p className="mt-4">
      We automatically collect certain information when you visit, use, or
      navigate the Services. This information does not reveal your specific
      identity (like your name or contact information) but may include device
      and usage information, such as your IP address, browser and device
      characteristics, operating system, language preferences, referring URLs,
      device name, country, location, information about how and when you use our
      Services, and other technical information. This information is primarily
      needed to maintain the security and operation of our Services, and for our
      internal analytics and reporting purposes.
    </p>
    <p className="mt-4">
      Like many businesses, we also collect information through cookies and
      similar technologies.
    </p>
    <p className="mt-4">The information we collect includes:</p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          <em>Log and Usage Data.</em> Log and usage data is service-related,
          diagnostic, usage, and performance information our servers
          automatically collect when you access or use our Services and which we
          record in log files. Depending on how you interact with us, this log
          data may include your IP address, device information, browser type,
          and settings and information about your activity in the Services (such
          as the date/time stamps associated with your usage, pages and files
          viewed, searches, and other actions you take such as which features
          you use), device event information (such as system activity, error
          reports (sometimes called 'crash dumps'), and hardware settings).
      </li>
      <li className="list-item">
          <em>Device Data.</em> We collect device data such as information about
          your computer, phone, tablet, or other device you use to access the
          Services. Depending on the device used, this device data may include
          information such as your IP address (or proxy server), device and
          application identification numbers, location, browser type, hardware
          model, Internet service provider and/or mobile carrier, operating
          system, and system configuration information.
      </li>
      <li className="list-item">
          <em>Location Data.</em> We collect location data such as information
          about your device's location, which can be either precise or
          imprecise. How much information we collect depends on the type and
          settings of the device you use to access the Services. For example, we
          may use GPS and other technologies to collect geolocation data that
          tells us your current location (based on your IP address). You can opt
          out of allowing us to collect this information either by refusing
          access to the information or by disabling your Location setting on
          your device. However, if you choose to opt out, you may not be able to
          use certain aspects of the Services.
      </li>
    </ul>
    </>,
  },
  {
    id: "infouse",
    heading: "How do we process your information?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent.
      </em>
    </p>
    <p className="mt-4">
      <strong>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </strong>
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          <strong>To respond to user inquiries/offer support to users. </strong>
          We may process your information to respond to your inquiries and solve
          any potential issues you might have with the requested service.
      </li>
      <li className="list-item">
          <strong>To request feedback. </strong>We may process your information
          when necessary to request feedback and to contact you about your use
          of our Services.
      </li>
      <li className="list-item">
          <strong>
            To send you marketing and promotional communications. 
          </strong>
          We may process the personal information you send to us for our
          marketing purposes, if this is in accordance with your marketing
          preferences. You can opt out of our marketing emails at any time. For
          more information, see '
          <a className="text-syr-snd font-nunb" href="#privacyrights">What are your privacy rights?</a>' below).
      </li>
      <li className="list-item">
          <strong>To deliver targeted advertising to you.</strong> We may
          process your information to develop and display personalised content
          and advertising tailored to your interests, location, and more.
      </li>
      <li className="list-item">
          <strong>To save or protect an individual's vital interest.</strong> We
          may process your information when necessary to save or protect an
          individual's vital interest, such as to prevent harm.
      </li>
    </ul>
    </>,
  },
  {
    id: "legalbases",
    heading: "What legal bases do we rely on to process your personal information?",
    body: <>
    <p className="mt-4">
      <em>
        <strong>In Short: </strong>We only process your personal information
        when we believe it is necessary and we have a valid legal reason
        (i.e. legal basis) to do so under applicable law, like with your
        consent, to comply with laws, to provide you with services to enter into
        or fulfil our contractual obligations, to protect your rights, or to
        fulfil our legitimate business interests.
      </em>
    </p>
    <p className="mt-4">
      <strong>If you are located in the EU or UK, this section applies to you.</strong>
    </p>
    <p className="mt-4">
      The General Data Protection Regulation (GDPR) and UK GDPR require us to
      explain the valid legal bases we rely on in order to process your personal
      information. As such, we may rely on the following legal bases to process
      your personal information:
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          <strong>Consent. </strong>We may process your information if you have
          given us permission (i.e. consent) to use your personal information
          for a specific purpose. You can withdraw your consent at any time.
      </li>
      <li className="list-item">
          <strong>Performance of a Contract.</strong> We may process your
          personal information when we believe it is necessary to fulfil our
          contractual obligations to you, including providing our Services or at
          your request prior to entering into a contract with you.
      </li>
      <li className="list-item">
          <strong>Legitimate Interests.</strong> We may process your information
          when we believe it is reasonably necessary to achieve our legitimate
          business interests and those interests do not outweigh your interests
          and fundamental rights and freedoms. For example, we may process your
          personal information for some of the purposes described in order to:
      </li>
      <li className="list-item">
          Send users information about special offers and discounts on our
          products and services
      </li>
      <li className="list-item">
          Develop and display personalised and relevant advertising content for
          our users
      </li>
      <li className="list-item">
          Understand how our users use our products and services so we can
          improve user experience
      </li>
      <li className="list-item">
          <strong>Legal Obligations.</strong> We may process your information
          where we believe it is necessary for compliance with our legal
          obligations, such as to cooperate with a law enforcement body or
          regulatory agency, exercise or defend our legal rights, or disclose
          your information as evidence in litigation in which we are involved.
      </li>
      <li className="list-item">
          <strong>Vital Interests.</strong> We may process your information
          where we believe it is necessary to protect your vital interests or
          the vital interests of a third party, such as situations involving
          potential threats to the safety of any person.
      </li>
    </ul>
    <p className="mt-4"><strong>If you are located in Canada, this section applies to you.</strong></p>
    <p className="mt-4">
      We may process your information if you have given us specific permission
      (i.e. express consent) to use your personal information for a specific
      purpose, or in situations where your permission can be inferred (i.e.
      implied consent).
    </p>
    <p className="mt-4">
      In some exceptional cases, we may be legally permitted under applicable
      law to process your information without your consent, including, for
      example:
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          If collection is clearly in the interests of an individual and consent
          cannot be obtained in a timely way
      </li>
      <li className="list-item">
        For investigations and fraud detection and prevention
      </li>
      <li className="list-item">
        For business transactions provided certain conditions are met
      </li>
      <li className="list-item">
          If it is contained in a witness statement and the collection is
          necessary to assess, process, or settle an insurance claim
      </li>
      <li className="list-item">
          For identifying injured, ill, or deceased persons and communicating
          with next of kin
      </li>
      <li className="list-item">
          If we have reasonable grounds to believe an individual has been, is,
          or may be victim of financial abuse
      </li>
      <li className="list-item">
          If it is reasonable to expect collection and use with consent would
          compromise the availability or the accuracy of the information and the
          collection is reasonable for purposes related to investigating a
          breach of an agreement or a contravention of the laws of Canada or a
          province
      </li>
      <li className="list-item">
          If disclosure is required to comply with a subpoena, warrant, court
          order, or rules of the court relating to the production of records
      </li>
      <li className="list-item">
          If it was produced by an individual in the course of their employment,
          business, or profession and the collection is consistent with the
          purposes for which the information was produced
      </li>
      <li className="list-item">
          If the collection is solely for journalistic, artistic, or literary
          purposes
      </li>
      <li className="list-item">
          If the information is publicly available and is specified by the
          regulations
      </li>
    </ul>
    </>,
  },
  {
    id: "whoshare",
    heading: "When and with whom do we share your personal information?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
      </strong>
      <em>
         We may share information in specific situations described in this
        section and/or with the following third parties.
      </em>
    </p>
    <p className="mt-4">
      We may need to share your personal information in the following
      situations:
    </p>
    <ul className="list-inside list-disc">
      <li className="list-item">
          <strong>Business Transfers.</strong> We may share or transfer your
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
      </li>
      <li className="list-item">
          <strong>Affiliates. </strong>We may share your information with our
          affiliates, in which case we will require those affiliates to honour
          this privacy notice. Affiliates include our parent company and any
          subsidiaries, joint venture partners, or other companies that we
          control or that are under common control with us.
      </li>
    </ul>
    </>,
  },
  {
    id: "cookies",
    heading: "Do we use cookies and other tracking technologies?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
      </strong>
      <em>
         We may use cookies and other tracking technologies to collect and store
        your information.
      </em>
    </p>
    <p className="mt-4">
      We may use cookies and similar tracking technologies (like web beacons and
      pixels) to access or store information. Specific information about how we
      use such technologies and how you can refuse certain cookies is set out in
      our Cookie Notice.
    </p>
    </>,
  },
  {
    id: "inforetain",
    heading: "How long do we keep your information?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We keep your information for as long as necessary to fulfil the purposes
        outlined in this privacy notice unless otherwise required by law.
      </em>
    </p>
    <p className="mt-4">
      We will only keep your personal information for as long as it is necessary
      for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other
      legal requirements).
    </p>
    <p className="mt-4">
      When we have no ongoing legitimate business need to process your personal
      information, we will either delete or anonymise such information, or, if
      this is not possible (for example, because your personal information has
      been stored in backup archives), then we will securely store your personal
      information and isolate it from any further processing until deletion is
      possible.
    </p>
    </>,
  },
  {
    id: "infosafe",
    heading: "How do we keep your information safe?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        We aim to protect your personal information through a system of
        organisational and technical security measures.
      </em>
    </p>
    <p className="mt-4">
      We have implemented appropriate and reasonable technical and
      organisational security measures designed to protect the security of any
      personal information we process. However, despite our safeguards and
      efforts to secure your information, no electronic transmission over the
      Internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or
      other unauthorised third parties will not be able to defeat our security
      and improperly collect, access, steal, or modify your information.
      Although we will do our best to protect your personal information,
      transmission of personal information to and from our Services is at your
      own risk. You should only access the Services within a secure environment.
    </p>
    </>,
  },
  {
    id: "infominors",
    heading: "Do we collect information from minors?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
      </strong>
      <em>
         We do not knowingly collect data from or market to children under 18
        years of age.
      </em>
    </p>
    <p className="mt-4">
      We do not knowingly solicit data from or market to children under 18 years
      of age. By using the Services, you represent that you are at least 18 or
      that you are the parent or guardian of such a minor and consent to such
      minor dependent's use of the Services. If we learn that personal
      information from users less than 18 years of age has been collected, we
      will deactivate the account and take reasonable measures to promptly
      delete such data from our records. If you become aware of any data we may
      have collected from children under age 18, please contact us at
      chantelle@syronahealth.com.
    </p>
    </>,
  },
  {
    id: "privacyrights",
    heading: "What are your privacy rights?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short:</em>
      </strong>
      <em>
         In some regions, such as the European Economic Area (EEA), United
        Kingdom (UK), and Canada, you have rights that allow you greater access
        to and control over your personal information. You may review, change,
        or terminate your account at any time.
      </em>
    </p>
    <p className="mt-4">
      In some regions (like the EEA, UK, and Canada), you have certain rights
      under applicable data protection laws. These may include the right (i) to
      request access and obtain a copy of your personal information, (ii) to
      request rectification or erasure; (iii) to restrict the processing of your
      personal information; and (iv) if applicable, to data portability. In
      certain circumstances, you may also have the right to object to the
      processing of your personal information. You can make such a request by
      contacting us by using the contact details provided in the section '
      <a className="text-syr-snd font-nunb" href="#contact">How can you contact us about this notice?</a>' below.
    </p>
    <p className="mt-4">
      We will consider and act upon any request in accordance with applicable
      data protection laws.
    </p>
    <p className="mt-4"> </p>
    <p className="mt-4">
      If you are located in the EEA or UK and you believe we are unlawfully
      processing your personal information, you also have the right to complain
      to your{" "}
      <a className="text-syr-snd font-nunb" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
        Member State data protection authority
      </a>{" "}
      or 
      <a className="text-syr-snd font-nunb" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
        UK data protection authority
      </a>
      .
    </p>
    <p className="mt-4">
      If you are located in Switzerland, you may contact the{" "}
      <a className="text-syr-snd font-nunb" href="https://www.edoeb.admin.ch/edoeb/en/home.html">
        Federal Data Protection and Information Commissioner
      </a>
      .
    </p>
    <p className="mt-4">
      <em>
        <strong>Opting out of marketing and promotional communications:</strong>
         
      </em>
      **You can unsubscribe from our marketing and promotional communications at
      any time by clicking on the unsubscribe link in the emails that we send,
      or by contacting us using the details provided in the section '
      <a className="text-syr-snd font-nunb" href="#contact">How can you contact us about this notice?</a>' below.
      You will then be removed from the marketing lists. However, we may still
      communicate with you — for example, to send you service-related messages
      that are necessary for the administration and use of your account, to
      respond to service requests, or for other non-marketing purposes.
    </p>
    <p className="mt-4">
      <strong>
        <em>Cookies and similar technologies:</em>
      </strong>{" "}
      Most Web browsers are set to accept cookies by default. If you prefer, you
      can usually choose to set your browser to remove cookies and to reject
      cookies. If you choose to remove cookies or reject cookies, this could
      affect certain features or services of our Services. You may also{" "}
      <a className="text-syr-snd font-nunb" href="http://www.aboutads.info/choices/">
        opt out of interest-based advertising by advertisers
      </a>{" "}
      on our Services.
    </p>
    <p className="mt-4">
      If you have questions or comments about your privacy rights, you may email
      us at support@syronahealth.com.
    </p>
    </>,
  },
  {
    id: "DNT",
    heading: "Controls for do-not-track features",
    body: <>
    <p className="mt-4">
      Most web browsers and some mobile operating systems and mobile
      applications include a Do-Not-Track ('DNT') feature or setting you can
      activate to signal your privacy preference not to have data about your
      online browsing activities monitored and collected. At this stage no
      uniform technology standard for recognising and implementing DNT signals
      has been finalised. As such, we do not currently respond to DNT browser
      signals or any other mechanism that automatically communicates your choice
      not to be tracked online. If a standard for online tracking is adopted
      that we must follow in the future, we will inform you about that practice
      in a revised version of this privacy notice.
    </p>
    </>,
  },
  {
    id: "caresidents",
    heading: "Do California residents have specific privacy rights?",
    body: <>
    <p className="mt-4">
      <strong>
        <em>In Short: </em>
      </strong>
      <em>
        Yes, if you are a resident of California, you are granted specific
        rights regarding access to your personal information.
      </em>
    </p>
    <p className="mt-4">
      California Civil Code Section 1798.83, also known as the 'Shine The Light'
      law, permits our users who are California residents to request and obtain
      from us, once a year and free of charge, information about categories of
      personal information (if any) we disclosed to third parties for direct
      marketing purposes and the names and addresses of all third parties with
      which we shared personal information in the immediately preceding calendar
      year. If you are a California resident and would like to make such a
      request, please submit your request in writing to us using the contact
      information provided below.
    </p>
    <p className="mt-4">
      If you are under 18 years of age, reside in California, and have a
      registered account with Services, you have the right to request removal of
      unwanted data that you publicly post on the Services. To request removal
      of such data, please contact us using the contact information provided
      below and include the email address associated with your account and a
      statement that you reside in California. We will make sure the data is not
      publicly displayed on the Services, but please be aware that the data may
      not be completely or comprehensively removed from all our systems
      (e.g. backups, etc.).
    </p>
    </>,
  },
  {
    id: "policyupdates",
    heading: "Do we make updates to this notice?",
    body: <>
    <p className="mt-4">
      <em>
        <strong>In Short: </strong>Yes, we will update this notice as necessary
        to stay compliant with relevant laws.
      </em>
    </p>
    <p className="mt-4">
      We may update this privacy notice from time to time. The updated version
      will be indicated by an updated 'Revised' date and the updated version
      will be effective as soon as it is accessible. If we make material changes
      to this privacy notice, we may notify you either by prominently posting a
      notice of such changes or by directly sending you a notification. We
      encourage you to review this privacy notice frequently to be informed of
      how we are protecting your information.
    </p>
    </>,
  },
  {
    id: "contact",
    heading: "How can you contact us about this notice?",
    body: <>
    <p className="mt-4">
      If you have questions or comments about this notice, you may contact our
      Data Protection Officer (DPO) , Chantelle Bell, by email
      at chantelle@syronahealth.com, or by post to:
    </p>
    <p className="mt-4">Syrona Limited</p>
    <p>Chantelle Bell</p>
    <p>Collingwood Buildings</p>
    <p>38 Collingwood Street</p>
    <p>Newcastle Upon Tyne</p>
    <p>NE1 1JF</p>
    <p>UK</p>
    </>,
  },
  {
    id: "request",
    heading: "How can you review, update, or delete the data we collect from you?",
    body: <>
    <p className="mt-4">
      Based on the applicable laws of your country, you may have the right to
      request access to the personal information we collect from you, change
      that information, or delete it. To request to review, update, or delete
      your personal information, please contact the DPO.
    </p>
    </>,
  },
]
